
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { DetailInfo } from '@/types/intelligentMachine'

@Component({
  name: 'MachineDetail',
  components: { MapLocation }
})
export default class extends Vue {
  private detailInfo: DetailInfo | null = null
  created () {
    console.log(this.$route)
    const id = this.$route.query.id as string
    this.getDetail(id)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.intelligentMachine.selectSmartMachineBySmartMachineId, {
        smartMachineId: id
      })
      .then((res) => {
        console.log(res)
        this.detailInfo = res || null
      })
  }
}
